import React from 'react'
import { PriceEstimatorContext } from '../../contexts/priceEstimatorContext'

export const Initial = ({className, hideEstimator}) => {
  const {nextStep } = React.useContext(PriceEstimatorContext)

    if(!hideEstimator) return (<section className={className}>
      <p className="paragraph">Select your desired options and get a rough estimate of the final cost!</p>
      <button className="step-0__get-started" onClick={nextStep}>Get Started</button>
    </section>)

    return null

}

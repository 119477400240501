import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import parse from "html-react-parser"
import React from "react"
import Layout from "../../components/Layout"
import { MetalColors } from "../../components/MetalColors"
import PageTitleSection from "../../components/pagebuilder-parts/PageTitleSection"
import { Pagination } from "../../components/Pagination"
import { PriceEstimatorContainer } from "../../components/price-estimator/PriceEstimatorContainer"
import SEO from "../../components/SEO"
import { SimpleList } from "../../components/SimpleList"
import { PriceEstimatorProvider } from "../../contexts/priceEstimatorContext"
const Product = ({ data, location, pageContext }) => {
  const [activeIndex, setActiveIndex] = React.useState(0)
  const {
    wpProduct: {
      title,
      slug,
      uri,
      product: {
        description,
        gallery,
        standardFeatures,
        repeaterFeatures,
        sizes,
        heightAsPercentage,
        productCategory,
        options,
        configuratorLink,
      },
    },
  } = data
  const { nextPage, previousPage } = pageContext
  const activeFullImage = (num) =>
    `product__img-gallery__image ${
      num === activeIndex
        ? "product__img-gallery__image--active"
        : "product__img-gallery__image--hidden"
    }`
  const isActive = (num) =>
    `product__img-thumbnail ${
      num === activeIndex ? "product__img-thumbnail--active" : ""
    }`
  const handleClick = (index) => {
    setActiveIndex(index)
  }

  const handleEnterOrTabPress = (handleClick) => ({ key }) => {
    if (key === "Enter") {
      handleClick()
    }
  }

  /**
   * price estimator logic below
   */

  /**
   * choose sides and ends closed for specific specialty designs
   */
  const hasSideOptions =
    productCategory === "specialty-designs" && title.includes("Extra Wide")
  const hasEndOptions =
    options.filter(({ slug }) => slug === "closed=in-end-per-end").length !==
      0 ||
    options.filter(({ slug }) => slug === "extra-wide-closed-end").length !== 0

  const legHeight =
    options.filter(({ slug }) => slug === "leg-height").length === 0
      ? heightAsPercentage
      : options.filter(({ slug }) => slug === "leg-height")[0].acfProductOptions
          .productOptions[0].optionColumn

  const sidesClosed =
    options.filter(({ slug }) => slug === "both-sides-closed").length !== 0
      ? options.filter(({ slug }) => slug === "both-sides-closed")[0]
          .acfProductOptions.productOptions[0].optionColumn
      : []

  let endsClosed
  if (productCategory === "standard-models") {
    endsClosed =
      options.filter(({ slug }) => slug === "closed-in-end-per-end").length !==
      0
        ? options.filter(({ slug }) => slug === "closed-in-end-per-end")[0]
            .acfProductOptions.productOptions[0].optionColumn
        : []
  } else if (
    productCategory === "specialty-designs" &&
    title.includes("Extra Wide")
  ) {
    endsClosed =
      options.filter(({ slug }) => slug === "extra-wide-closed-end").length !==
      0
        ? options.filter(({ slug }) => slug === "extra-wide-closed-end")[0]
            .acfProductOptions.productOptions[0].option
        : []
  }

  const renderPriceEstimator = (productCategory) => {
    switch (productCategory) {
      case "standard-models":
        return (
          <PriceEstimatorProvider
            productUri={uri}
            sizes={sizes}
            legHeight={legHeight}
            sidesClosed={sidesClosed}
            endsClosed={endsClosed}
            productCategory={productCategory}
          >
            <PriceEstimatorContainer
              sizes={true}
              legHeight={true}
              sidesClosed={true}
              endsClosed={true}
            />
          </PriceEstimatorProvider>
        )
      case "deluxe-garages":
        return (
          <PriceEstimatorProvider
            productUri={uri}
            productCategory={productCategory}
            sizes={sizes}
            legHeight={legHeight}
            heightAsPercentage={heightAsPercentage}
          >
            <PriceEstimatorContainer sizes={true} legHeight={true} />
          </PriceEstimatorProvider>
        )
      case "specialty-designs":
        return (
          <PriceEstimatorProvider
            productUri={uri}
            productCategory={productCategory}
            sizes={sizes}
            legHeight={heightAsPercentage}
            heightAsPercentage={heightAsPercentage}
            sidesClosed={sidesClosed}
            endsClosed={endsClosed}
          >
            <PriceEstimatorContainer
              sidesClosed={hasSideOptions}
              endsClosed={hasEndOptions}
              sizes={true}
              legHeight={true}
            />
          </PriceEstimatorProvider>
        )
    }
  }

  // remove product options that aren't supposed to appear under
  // extras and other options
  const slugsToRemove = [
    "both-sides-closed",
    "closed-in-end-per-end",
    "leg-height",
    "metal-color-options",
    "extra-wide-closed-end",
  ]
  const allProductOptions = options?.filter(({ slug }) => {
    return !slugsToRemove.includes(slug)
  })
  return (
    <Layout>
      <SEO title={title} description={description} />
      <PageTitleSection data={{ title }} location={location} />
      <section className="product">
        {slug && slug === "run-in-animal-shelter" && (
          <div
            style={{
              width: `96%`,
              margin: `4rem auto`,
              backgroundColor: `darkred`,
              padding: `2rem`,
              textAlign: `center`,
              color: `white`,
            }}
          >
            <p className="paragraph" style={{ color: `white`, margin: 0 }}>
              Due to lumber fluctuation, there is currently a surcharge on the
              wood used in Animal Shelters. Please contact our office to confirm
              prices.
            </p>
          </div>
        )}
        <div className="row">
          <div className="col-1-of-2">
            <div className="product__img-gallery">
              {gallery.map((item, index) => {
                const { fullImage, altText } = item
                return (
                  <div className={activeFullImage(index)} key={item.id}>
                    <GatsbyImage
                      image={getImage(fullImage)}
                      alt={altText}
                    />
                  </div>
                )
              })}
            </div>
            <div className="product__img-thumbnails">
              {gallery.map((item, index) => {
                const { fixedImage, altText } = item
                return (
                  <div
                    className={isActive(index)}
                    tabIndex="0"
                    onClick={(e) => {
                      handleClick(index)
                    }}
                    onKeyPress={(e) => {
                      handleEnterOrTabPress(handleClick(index))
                    }}
                    key={item.id}
                  >
                    <GatsbyImage
                      image={getImage(fixedImage)}
                      alt={altText}
                    />
                  </div>
                )
              })}
            </div>
          </div>
          <div className="col-1-of-2">
            <article className="product__description">
              <h1 className="product__title">{parse(title)}</h1>
              <p className="paragraph">{parse(description)}</p>
            </article>
          </div>
        </div>
        <section className="product__info">
          <div className="row">
            <div className="col-1-of-3 u-align-center">
              {standardFeatures != null || repeaterFeatures || null ? (
                <h3>Standard Features</h3>
              ) : null}
              <ul className="product__features-list">
                {repeaterFeatures?.map(({ name, id }, index) => (
                  <li
                    className="features-list__item"
                    key={`${index}${name}+${id}`}
                  >
                    {parse(name)}
                  </li>
                ))}
                {standardFeatures?.map(({ title, id }, index) => (
                  <li
                    key={`${title}-${id}--${index}`}
                    className="features-list__item"
                  >
                    {parse(title)}
                  </li>
                ))}
              </ul>
            </div>
            <div className="col-2-of-3 ">
              {renderPriceEstimator(productCategory)}
            </div>
          </div>
        </section>
        <section className="product__options">
          <div className="wrapper">
            <h2 className="heading-secondary u-center-text">
              Extras &amp; other options
            </h2>
            <div className="product__options__grid">
              {allProductOptions?.map(
                (
                  { id, title, acfProductOptions: { productOptions } },
                  index
                ) => {
                  let list = productOptions?.map(
                    ({ __typename, option }, index) => {
                      switch (__typename) {
                        case "WpProductOption_Acfproductoptions_ProductOptions_SimpleList":
                          return (
                            <SimpleList
                              className="simple-list"
                              key={`${__typename}-${index}`}
                              options={option}
                            />
                          )
                      }
                    }
                  )
                  return (
                    <div key={id} className="product__options__grid-item">
                      <h6 className="product__options__grid-item-title u-center-text">
                        {parse(title)}
                      </h6>
                      {list}
                    </div>
                  )
                }
              )}
              {options
                .filter((o) => o.slug === "metal-color-options")
                .map((option) => {
                  return (
                    <div
                      key={option.id}
                      className="product__options__grid-item--colors"
                    >
                      <h6 className="product__options__grid-item-title u-center-text u-margin-bottom-lg">
                        {parse(option.title)}
                      </h6>
                      <MetalColors colors={[option]} />
                    </div>
                  )
                })}
            </div>
          </div>
        </section>
      </section>
      {(nextPage?.uri || previousPage?.uri !== null) && (
        <Pagination previousPage={previousPage} nextPage={nextPage} />
      )}
    </Layout>
  )
}

export default Product

export const ProductQuery = graphql`
  query SingleProduct($id: String) {
    wpProduct(id: { eq: $id }) {
      ...ProductExcerpt
      slug
      product {
        productCategory
        heightAsPercentage {
          legHeight
          percentage
        }
        sizes {
          size
          price
        }
        repeaterFeatures {
          name
        }
        standardFeatures {
          ... on WpStandardFeature {
            title
            id
          }
        }
        options {
          ... on WpProductOption {
            id
            title
            slug
            acfProductOptions {
              productOptions {
                ... on WpProductOption_Acfproductoptions_ProductOptions_SimpleList {
                  option {
                    descriptor
                    price
                  }
                }
                ... on WpProductOption_Acfproductoptions_ProductOptions_ComplexList {
                  optionColumn {
                    header
                    optionRowHeader {
                      optionPrice
                      optionRow
                    }
                  }
                }
                ... on WpProductOption_Acfproductoptions_ProductOptions_MetalColors {
                  colors {
                    value
                    name
                  }
                }
              }
            }
          }
        }
        gallery {
          id
          altText
          fixedImage: localFile {
            childImageSharp {
              gatsbyImageData(width: 100, height: 100, layout: FIXED)
            }
          }
          fullImage: localFile {
            ...HeroImage
          }
        }
      }
    }
  }
`

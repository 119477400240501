import PropTypes from 'prop-types'
import React from 'react'
import { formatCurrency } from '../utils/formatCurrency'

export const SimpleList = ({options, className="simple-list"}) => {
  return (
    <ul className={className}>
       {options.map(({ descriptor, price }) => (
        <li key={price} className={`${className}__list-item`}>
          <span className={`${className}__list-item--descriptor`}>
            {descriptor}
          </span>
          {price && price !== null && (
            <span className={`${className}__list-item--price`}>
              {formatCurrency(price)}
            </span>
          )}
        </li>
      ))}
    </ul>
  )
}

SimpleList.propTypes = {
  options: PropTypes.array.isRequired,
}


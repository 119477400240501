import React from "react"
import { FiArrowUp } from "react-icons/fi"
import { PriceEstimatorContext } from "../../contexts/priceEstimatorContext"
import { ProgressBar } from "../forms/ProgressBar"

export const PriceEstimator = ({ children , hideEstimator}) => {
  const {
    formData: { size },
    step,
    nextStep,
    previousStep,
    calculateResults,
  } = React.useContext(PriceEstimatorContext)

  /**
   * Convert react children to array to determine the rendered component
   */
  const childrenToArray = React.Children.toArray(children)
  const isInitialComponent = step && step > 0 ? false : true
  const isSizeSelected = size.value.length > 0
  const numberOfSteps = childrenToArray.length - 1

  const renderChildComponent = (step) => {
    return childrenToArray[step]
  }
  return (
    <form className="price-estimator">
      <h3>Base Price Estimator</h3>
      {!isInitialComponent && !hideEstimator && (
        <ProgressBar className="price-estimator__progress-bar" />
      )}
      {renderChildComponent(step)}
      {!isSizeSelected && step > 0 && !hideEstimator && (
        <div className="price-estimator__error-msg">
          <p className="paragraph small">
            Size must be selected before continuing.
          </p>
        </div>
      )}
      {hideEstimator && (
        <div className="price-estimator__error-msg">
          <p className="paragraph">
            See starting prices above <FiArrowUp />
          </p>
        </div>
      )}
      {step > 0 && step < numberOfSteps && (
        <div className="price-estimator__navigation-buttons">
          <button onClick={previousStep} className="btn btn--small btn--white">
            Go Back
          </button>
          <button
            disabled={!isSizeSelected}
            onClick={(e) => {
              step < numberOfSteps
                ? nextStep(e, numberOfSteps)
                : calculateResults(e, numberOfSteps)
            }}
            className={`btn btn--small btn--primary ${
              !isSizeSelected ? "btn--disabled" : ""
            }`}
          >
            {step < numberOfSteps - 1 ? "Continue" : "Calculate"}
          </button>
        </div>
      )}
    </form>
  )
}

import React from 'react'
import { PriceEstimatorContext } from '../../contexts/priceEstimatorContext'
export const Confirm = (className) => {
  const {formData, setStep, step, productCategory} = React.useContext(PriceEstimatorContext)

  const itemDetails = Object.entries(formData).slice(0, step -1)
  return (
    <div className={className}>
      <h4>Confirm details</h4>
      <ul className="price-estimator__confirm-list">
        {itemDetails.map(([key, value], index) => {
          const title = key.match(/[A-Z]+[^A-Z]*|[^A-Z]+/g).join(" ")
          return (
            <li key={key}>
              <span className="price-estimator__confirm-list-item-title">
                <a onClick={e => setStep(step => index + 1)}>{title}</a>
              </span>{" "}
              - {value.value}
            </li>
          )
        })}
        {productCategory === "deluxe-garages" && (
          <li>
            <span className="price-estimator__confirm-list-item-title">
              Fully Enclosed
            </span>
          </li>
        )}
      </ul>
    </div>
  )
}

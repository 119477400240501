import React from "react"
import { PriceEstimatorContext } from "../../contexts/priceEstimatorContext"
import { CustomSelect } from '../forms/CustomSelect'
export const HeightDetails = ({ className }) => {
  const {
    legHeight: legHeightOptions,
    handleChange,
    productCategory,
    productUri,
    formData: {
      legHeight, size,
    },
  } = React.useContext(PriceEstimatorContext)
  const legHeightArray =
    productCategory !== "standard-models"
      ? legHeightOptions
      : legHeightOptions
          .filter(({ header }) => header.includes(size.length))
          .map(({ optionRowHeader }) => optionRowHeader)
          .flat()
  const initialLegHeight = productCategory=== 'standard-models' ? legHeightArray.find(v => v.optionPrice === 0).optionRow: legHeightArray.find(v => v.percentage === 0).legHeight
  return (
    <section className={className}>
      <h4>Choose your height</h4>
      <p className="paragraph">
        Leg height is measured as the actual length of the leg.{" "}
        {productUri === "rounded-frame" && (
          <>
            On Rounded Frame carports, there is approximately 12" extra to the
            roof slope to account for the bend in the truss.
          </>
        )}
      </p>
      <p className="paragraph small">
        In most cases, we recommend at least a 7' leg so that there is plenty of
        clearance due to the angled corner braces. Carports higher than 12' will
        have an additional equipment charge to set the trusses
      </p>
      <CustomSelect
        name="legHeight"
        hintText="Choose leg height"
        labelText="Leg Height"
        handleChange={handleChange("legHeight")}
        defaultValue={legHeight.value || initialLegHeight}
      >
        {legHeightArray.map((legHeight, index) => {
          const {
            optionRow,
            optionPrice,
            legHeight: wallHeight,
            percentage,
          } = legHeight
          const value =
            optionRow && optionRow !== undefined ? optionRow : wallHeight
          const price =
            optionPrice && optionPrice !== undefined
              ? optionPrice
              : Math.ceil(size.price * percentage)
          if(percentage === null) return <optgroup key={index} label={value} />
          return (
            <option
              // disabled={percentage === null}
              data-price={isNaN(price) ? 0 : price}
              value={value}
              key={index}
            >
              {value}
            </option>
          )
        })}
      </CustomSelect>
    </section>
  )
}

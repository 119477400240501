import React from "react"
import { PriceEstimatorContext } from "../../contexts/priceEstimatorContext"
import { formatPhone } from "../../utils/formatPhone"
import CustomSelect from "../forms/CustomSelect"

export const SizeDetails = ({ className }) => {
  const {
    sizes,
    handleChange,
    formData: { size },
  } = React.useContext(PriceEstimatorContext)

  return (
    <section className={className}>
      <h4>Choose your size</h4>
      <p className="paragraph small">
        When selecting your size, consider that, for a usable "double car"
        carport, you'll need at least a 20' wide model.
      </p>
      <p className="paragraph">
        <span>
          If you need a custom size not listed here, give us a call at{" "}
        </span>
        <a href={`tel:+1-931-635-2244`} className="price-estimator__phone">
          {formatPhone("9316352244")}
        </a>
      </p>
      <CustomSelect
        name="size"
        labelText="Size"
        hintText="Choose your size"
        handleChange={handleChange("size")}
        defaultValue={size.value || ""}
      >
        {sizes.map(({ price, size: option }, index) => {
          if (price == null) return <optgroup key={`${price}-${option}--${index}`} label={option} />

          return (
            <option
              style={{marginLeft:`4rem`}}
              key={`${price}-${option}--${index}`}
              value={option}
              data-price={price}
              disabled={price == null}
            >
              {option}
            </option>
          )
        })}
      </CustomSelect>
    </section>
  )
}

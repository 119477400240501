import React from 'react'
import { PriceEstimatorContext } from '../../contexts/priceEstimatorContext'
export const Calculate = ({className}) => {
  const {formData, resetStep} = React.useContext(PriceEstimatorContext)

  const priceEstimate = Object.values(formData).reduce(function(total, currentValue) {
    return Number(total) + Number(currentValue.price)
  }, 0)
  return (
    <section className={className}>
      <h5 className="price-calc__title">${priceEstimate} + tax</h5>
      <p className="paragraph">
        This price is an estimate and does not factor in any extra options or
        charges. <br />
        <span className="price-calc__callout">
          Many other options available! See local dealer for details!
        </span>
      </p>
      <div className="price-calc__button-group">
        <button
          className="btn btn--small btn--accent-light"
          type="reset"
          onClick={resetStep}
        >
          Reset Form
        </button>
      </div>
    </section>
  )
}

import React from "react"
import { PriceEstimatorContext } from "../../contexts/priceEstimatorContext"
import { Calculate } from "./Calculate"
import { Confirm } from "./Confirm"
import { EndsDetails } from "./EndsDetails"
import { HeightDetails } from "./HeightDetails"
import { Initial } from "./Initial"
import { PriceEstimator } from "./PriceEstimator"
import { SidesDetails } from "./SidesDetails"
import { SizeDetails } from "./SizeDetails"

export const PriceEstimatorContainer = ({
  sizes,
  legHeight,
  sidesClosed,
  endsClosed,
}) => {
  const { productUri } = React.useContext(PriceEstimatorContext)

  const hideEstimator =
    productUri.includes("extra-wide") || productUri.includes("tennessee-barn")
  return (
    <PriceEstimator hideEstimator={hideEstimator}>
      <Initial hideEstimator={hideEstimator} />
      {!hideEstimator && sizes && <SizeDetails />}
      {!hideEstimator && legHeight && <HeightDetails />}
      {!hideEstimator && (
        <>
          <Confirm />
          <Calculate />
        </>
      )}
    </PriceEstimator>
  )
}
